$plans:
  'mobius_early_bird' $color-green,
  'mobius_professional' $color-green,
  'mobius_student' $color-green,
  'plan-4' $color-green,
  'mobius_business_monthly' $color-green,
  'mobius_professional_monthly' $color-green,
  'mobius_business_yearly' $color-green,
  'mobius_professional_yearly' $color-green;
@mixin plan-item($class-name, $color) {
  width: 100%;
  &.plan-#{$class-name}{
    .name{
      color: $color;
    }
    .features .anticon{
      color: $color;
    }
    .plan-button{
      background-color: $color;
      opacity: 1;
      &:hover{
        opacity: 0.9;
      }
    }
  }

  .ant-card-body {
    height: calc(100% - 220px);
  }
}

.payment{
  margin-bottom: $ANT-spacing-16;
}

.plan-item{
  border: none;
  padding: $ANT-spacing-16 0;

  .name{
    text-transform: uppercase;
    font-weight: $semibold;
    margin-bottom: 2em;
    font-size: $font-size-s;
  }
  .price{
    font-size: $font-size-xl;
    font-weight: normal;
  }
  .pay-rate{
    font-size: $font-size-s;
    color: $color-text-grey;
    height: 1em;
  }
  .description{
    font-size: $font-size-s;
    color: $color-text-grey-dark;
    font-weight: bold;
    height: 7em;
  }
  .features{
    font-size: $font-size-s;
    color: $color-text-grey-dark;
    .title{
      font-weight: bold;
      line-height: 2em;
    }
    .features-item{
      padding: $ANT-spacing-4 0;
    }
  }
  .plan-button{
    clear: both;
    border: none;
    color: $color-text-inverse;
  }

  .ant-card-head,
  .ant-card-actions{
    background-color: $color-background-light;
    border:none;
  }
  @each $plan, $color in $plans{
    @include plan-item($plan, $color);
  }
}

.plan-item.highlighted {
  height: calc(100% - 3.75rem);
}
